<template>
  <main class="layout__body">
    <div class="food-order">
      <p class="mb-12">Ваш заказ</p>
      <div 
        v-for="(item, k) in items"
        class="food-order__item"
        :key="k"
      >
        <p class="small">{{ item.info.title }} x {{ item.qty }} </p>
        <p class="caption">{{ item.info.price * item.qty }}₸</p>
      </div>
      <div class="food-order__divider mb-8"></div>
      <div class="food-order__item mb-4">
        <p class="caption text-gray">Дотация</p>
        <p class="caption text-succsesfull">421 ₸</p>
      </div>
      <div class="food-order__item">
        <p class="small">Доплатить</p>
        <p style="font-weight: 600;">421 ₸</p>
      </div>
      <div class="text-center mt-32">
        <p 
          v-if="qrGenerateErr"
        >Возникла ошибка при генерации QR-кода</p>
        <img 
          v-else-if="!qrGenLoading"
          :src="imgDataUrl" 
          alt="QR"
        >

      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import { toDataURL } from 'qrcode';
const qrOptions = { 
  type: 'image/jpeg',
  quality: 0.3,
  margin: 1,
  color: {
    dark:"#000000",
    // light:"#FFBF60FF"
  }
}
export default {
  name: 'FoodOrderView',
  data(){
    return {
      qrGenerateErr: false,
      qrGenLoading: true,
      imgDataUrl: undefined,
    }
  },
  computed: {
    ...mapState('food', ['items'])
  },
  methods: {
    async generateQrCode(str){
      this.qrGenLoading = true;
      try {
        this.imgDataUrl = await toDataURL(str, qrOptions)
      } catch {
        this.qrGenerateErr = true;
      }
      this.qrGenLoading = false;
    }
  },
  mounted(){
    this.generateQrCode('https://assets-prd.ignimgs.com/2020/10/01/borat2-button-1601576298983.jpg')
  }
}
</script>
<style lang="scss">
.food-order{
  padding: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px;
  .food-order__item{
    display: flex; 
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .food-order__divider{
    border: 1px dashed #DDE1E5;
  }
}
</style>