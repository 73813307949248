<template>
  <main class="layout__body">
      <router-link
        tag="div"
        v-for="(historyItem, k) in historyItems"
        class="food-history__item"
        :key="k"
        :to="{
          name: 'FoodHistoryByIdPage',
          params: {
            id: k
          },
        }"
      >
        <div class="food-history__item__left">
          <p class="small-medium">{{ historyItem.title }}</p>
          <p class="food-history__item__date">{{ historyItem.date }}</p>
          <p class="food-history__item__price">{{ historyItem.price }} ₸</p>
        </div>
        <div class="food-history__item__right">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
      </router-link>
  </main>
</template>

<script>
export default {
  name: 'FoodHistoryView',
  data(){
    return {
      historyItems: [
        {
          title: 'Cуп, Мясо по Тайский, Хлеб, Компот',
          date: '10.11.2021',
          price: 3110,
        },
        {
          title: 'Cуп, Мясо по Тайский, Хлеб, Компот, Cуп, Мясо по Тайский, Хлеб, Компот',
          date: '10.11.2021',
          price: 2220,
        },
        {
          title: 'Cуп, Мясо по Тайский, Хлеб, Компот',
          date: '10.11.2021',
          price: 400,
        },
        {
          title: 'Cуп, Мясо по Тайский, Хлеб, Компот',
          date: '10.11.2021',
          price: 500,
        },
        {
          title: 'Cуп, Мясо по Тайский, Хлеб, Компот',
          date: '10.11.2021',
          price: 50,
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.food-history__item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px; 
  padding: 12px 10px;
  width: 100%;
  max-width: 100%;
  .food-history__item__left{
    min-width: 0;
    margin-right: 8px;
    p{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .food-history__item__date{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.1px;
      color: #667A8B;
    }
    .food-history__item__price{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-top: 4px;
      /* identical to box height, or 125% */

      letter-spacing: -0.1px;

      color: #34BC9B;
    }
  }
  .food-history__item__right{
    margin: 8px; 
  }
}
</style>