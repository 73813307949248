<template>
  <main class="layout__body">
    <div class="food__info">
      <p class="mb-12">4 сентября</p>
      <p class="food__info__dollars">
        <span>{{ "2471" }}</span>
        <span class="food__info__cents">.00 ₸</span>
      </p>
      <p class="small text-gray">Ваш баланс</p>
    </div>
    <router-link 
      tag="div"
      :to="{ name: 'FoodQrPage' }"
      class="food__qr-btn"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.428 3c.42 0 .76.334.76.747 0 .412-.34.746-.76.746l-1.63.001c-1.351.002-2.451 1.082-2.451 2.409v1.859a.756.756 0 01-.762.747.755.755 0 01-.761-.747v-1.86c0-2.149 1.783-3.9 3.974-3.901L8.427 3zM15.6 3h1.593c2.197 0 3.983 1.752 3.983 3.906v1.856c0 .412-.34.748-.761.748a.755.755 0 01-.761-.748V6.906c0-1.33-1.104-2.413-2.461-2.413H15.6a.753.753 0 01-.761-.745c0-.413.34-.748.76-.748zm-.839 3.743H9.238c-1.263.012-2.28 1.025-2.268 2.266v1.245a.278.278 0 00.27.269h9.52a.28.28 0 00.272-.269V9.01a2.266 2.266 0 00-.66-1.603 2.272 2.272 0 00-1.61-.663zm-13 5.298h20.477c.42 0 .761.335.761.748a.753.753 0 01-.761.745h-1.062v3.56c0 2.155-1.787 3.906-3.983 3.906h-1.593a.755.755 0 01-.762-.747c0-.412.341-.747.762-.747h1.593c1.357 0 2.461-1.081 2.461-2.413v-3.56h-2.623v.992c.01 1.24-1.005 2.254-2.27 2.266H9.238c-1.264-.012-2.279-1.025-2.269-2.266v-.991H4.346v3.563c0 1.327 1.101 2.407 2.454 2.409h1.628c.42 0 .761.335.761.747a.755.755 0 01-.762.747l-1.629-.001c-2.191-.002-3.975-1.752-3.975-3.902v-3.563H1.762A.754.754 0 011 12.789c0-.413.342-.748.762-.748z" fill="#34BC9B"/></svg>
      <p class="small-medium">Ваш QR код</p>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'qr-btn__right-arrow'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </router-link>
    <div class="food__dotacii">
      <p class="food__dotacii__title">Дотация</p>
      <div class="dotacii__items">
        <div 
          v-for="(item, key) in dotacii"
          :key="key"
          class="dotacii__item"
        >
          <div class="dotacii__item__box">
            <img :src="item.src" alt="">
            <p class="dotacii__item__title">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <p class="caption-medium mb-16">🍉 МЕНЮ НА СЕГОДНЯ 04.01.2022</p>
    <div class="food-menu">
      <div 
        v-for="(foodItem, foodKey) in foodMenu"
        :key="foodKey"
        class="food-menu__item"
        :class="{
          'food-menu__item--active': activeFoodKey === foodKey
        }"
        @click="activeFoodKey=foodKey"
      >
        <p class="small-medium">{{ foodItem.title }}</p>
        <p class="food-menu__item__description">{{ foodItem.description }}</p>
        <div 
          class="food-menu__item__bottom"
        >
          <p class="food-menu__item__bottom__price">{{ foodItem.price }} ₸</p>
          <div 
            v-if="activeFoodKey === foodKey"
            class="food-menu__item__bottom__qty-control"
          >
            <div 
              class="qty-control__btn"
              @click="$store.dispatch('food/removeItem', foodItem)"  
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.634 10a.7.7 0 01.7-.7h13.929a.7.7 0 110 1.4H3.333a.7.7 0 01-.7-.7z" fill="#151C22"/></svg>
            </div>
            <p class="qty-control__qty">
              {{ qty_by_uid(foodItem.uid) }}
            </p>
            <div 
              class="qty-control__btn"
              @click="$store.dispatch('food/addItem', foodItem)"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.998 2.5a.7.7 0 10-1.4 0v6.8H3.334a.7.7 0 100 1.4h6.264v6.8a.7.7 0 101.4 0v-6.8h6.265a.7.7 0 100-1.4h-6.265V2.5z" fill="#151C22"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FoodIndexView',
  data(){
    return {
      activeFoodKey: undefined,
      dotacii: [
        {
          title: '42 500 ₸',
          src: require('@/assets/food_wallet.png')
        },
        {
          title: 'Молоко',
          src: require('@/assets/food_milk.png')
        },
        {
          title: 'Молоко',
          src: require('@/assets/food_milk.png')
        },
      ],
      foodMenu: [
        {
          uid: 1,
          title: 'Яйцо Бенедикт',
          description: 'Пшеничная тортилья, моцарелла, запеченная курочка, соус ранч, томаты',
          price: 3110,
        },
        {
          uid: 2,
          title: 'Русский завтрак',
          description: 'Пшеничная тортилья, моцарелла, запеченная курочка, соус ранч, томаты',
          price: 2220,
        },
        {
          uid: 3,
          title: 'Борщ',
          description: 'Пшеничная тортилья, моцарелла, запеченная курочка, соус ранч, томаты',
          price: 400,
        },
        {
          uid: 4,
          title: 'Пельмешки со сметанкой',
          description: 'Пшеничная тортилья, моцарелла, запеченная курочка, соус ранч, томаты',
          price: 500,
        },
        {
          uid: 5,
          title: 'Доширак',
          description: 'Пшеничная тортилья, моцарелла, запеченная курочка, соус ранч, томаты',
          price: 50,
        }
      ]
    }
  },
  computed: {
    ...mapGetters('food', ['qty_by_uid'])
  }
}
</script>
<style lang="scss">
.food__info{
  padding: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px;
  .food__info__dollars{
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01em;
    // font-feature-settings: 'ss01' on, 'salt' on, 'ordn' on, 'kern' off;
    color: #151C22;
  }
  .food__info__cents{
    color: #DDE1E5;
  }
}
.food__qr-btn{
  display: flex;
  align-items: center;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px;
  p{
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .qr-btn__right-arrow{
    // width: 24px;
    // height: 24px;
    margin-left: auto;
    margin-right: 20px;
  }
}
.food__dotacii{
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 20px;
  .food__dotacii__title{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #151C22;
    margin: 0 0 12px;
  }
  .dotacii__items{
    display: flex;
    flex-wrap: wrap;
  }
  .dotacii__item{
    text-align: center;
    flex: 1 0 50%;
    margin: 0 0 8px;
    &:nth-child(odd){
      padding-right: 8px;
    }
    &:nth-child(even){
      padding-right: 8px;
    }
    .dotacii__item__box{
      border: 0.5px solid #DDE1E5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px;
      img{
        margin-bottom: 4px;
      }
      .dotacii__item__title{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }
  }
}
.food-menu{
  .food-menu__item{
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px; 
    padding: 12px 10px;
    &.food-menu__item--active{
      border: 2px solid #34BC9B;
    }
    .food-menu__item__description{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.1px;
      color: #667A8B;
    }
    .food-menu__item__bottom{
      display: flex;
      align-items: center;
      .food-menu__item__bottom__price{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: -0.1px;

        color: #34BC9B;
      }
      .food-menu__item__bottom__qty-control{
        display: flex;
        align-items: center;
        margin-left: auto;
        min-height: 36px;
        .qty-control__btn{
          display: flex;
          align-items: center;
          justify-content: center;
          background: #F9FAFB;
          border-radius: 8px;
          height: 36px;
          width: 36px;
          &:active{
            background: #34BC9B;
          }
        }
        .qty-control__qty{
          min-width: 30px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }
    }
  }
}
</style>