<template>
  <main class="layout__body">
    <div class="food-history-info">
      <div 
        v-for="(item, k) in items"
        class="food-history-info__row mb-16"
        :key="k"
      >
        <p class="small">{{ item.info.title }} x {{ item.qty }} </p>
        <p class="caption text-green">{{ item.info.price * item.qty }}₸</p>
      </div>
      <div class="food-history-info__divider mb-8"></div>
      <p class="caption text-gray mb-4">Наличный рассчет</p>
      <div class="food-history-info__row mb-8">
        <p class="caption text-gray">Дотация</p>
        <p class="caption text-succsesfull">-1 421 ₸</p>
      </div>
      <div class="food-history-info__row">
        <p class="small">Итого</p>
        <p style="font-weight: 600;">421 ₸</p>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'FoodHistoryItem', 
  data(){
    return {
      items: [
        {
          info: {
            title: 'Русский завтрак',
            price: 3110,
          },
          qty: 2
        },
        {
          info: {
            title: 'Ролл «БОМБА»',
            price: 3110,
          },
          qty: 2
        },
        {
          info: {
            title: 'Яйцо Бенедикт',
            price: 3110,
          },
          qty: 1
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.food-history-info{
  background: #FFFFFF;
  padding: 16px;
  border-radius: 8px;
  .food-history-info__row{
    display: flex; 
    justify-content: space-between;
  }
  .food-history-info__divider{
    border: 0.5px solid #DDE1E5;
  }
}
</style>