<template>
  <div class="header--food">
    <div
      @click="handleBackClick"
      class="header--food__back-link"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 4L8 12L16 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <p class="header--food__text">👋 Салем, Aydar</p>
    <router-link
      replace
      tag="div"
      :to="iconTo"
      class="header--food__right-icon"
    >
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".4" d="M18.89 2.333H9.112C5.565 2.333 3.5 4.41 3.5 7.968V20.02c0 3.617 2.065 5.647 5.612 5.647h9.778c3.603 0 5.61-2.03 5.61-5.647V7.968c0-3.558-2.007-5.635-5.61-5.635z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M9.427 7.758v.012a.91.91 0 000 1.82h3.487a.914.914 0 00.911-.923c0-.5-.408-.909-.91-.909H9.426zm9.147 7.105H9.427a.91.91 0 010-1.82h9.147a.911.911 0 010 1.821zm0 5.332H9.427a.906.906 0 01-.875-.42.927.927 0 01.875-1.412h9.147a.915.915 0 01.816.922.913.913 0 01-.816.91z" fill="#fff"/></svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FoodHeaderView",
  computed: {
    iconTo(){
      return {
        name: 'FoodHistoryPage',
      }
    }
  },
  methods: {
    handleBackClick(){
      if (this.$route.name === "FoodPage") {
        this.$router.replace({ name: 'MainPage', });
      } else {
        this.$router.replace({ name: 'FoodPage', });
      }      
    }
  },
  mounted(){
    this.$store.dispatch('layout/setThemeColor', '#34BC9B');
    this.$nextTick(() => {
      let headerHeight = this.$el.parentElement.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  },

  updated(){
    this.$store.dispatch('layout/setThemeColor', '#34BC9B');
    this.$nextTick(() => {
      let headerHeight = this.$el.parentElement.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  }
}
</script>
<style lang="scss">
.header--food{
  display: flex;
  // justify-content: space-between;
  align-items: center;
  justify-content: space-between;
}
.header--food__back-link{
  display: flex; 
  align-items: center;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  margin: 0 12px 0 0;
  // overflow-wrap: anywhere;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg{
    flex-shrink: 0;
  }
}
.header--food__text{
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
}
.header--food__right-icon{
  margin: 0 0 0 12px;
  color: transparent;
}
.header--food__right-icon--active{
  color: white;
}
</style>