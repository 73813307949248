<template>
  <div 
    v-show="qty_total || price_total"
    class="food-footer"
  >
    <router-link
      tag="div" 
      class="food-footer__price-btn"
      :to="{
        name: 'FoodOrderPage',
      }"  
    >
      <div class="food-footer__price-btn__qty">
        <p class="small text-black">{{ qty_total }}</p>
      </div>
      <p class="large-medium">
        Итого - {{ price_total }},00 ₸
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FoodFooter',
  computed: {
    ...mapGetters('food', ['price_total', 'qty_total'])
  },
}
</script>

<style lang="scss">
.food-footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 8px 16px;
  background-color: #FFF;
  .food-footer__price-btn{
    position: relative;
    padding: 16px;
    background: #0086E7;
    color: #FFF;
    text-align: center;
    border-radius: 12px;
    .food-footer__price-btn__qty{
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 6px;
      min-width: 26px;
      height: 20px;
      background: #FFFFFF;
      border-radius: 50px;
    }
  }
}
</style>