<template>
  <main class="layout__body">
    <div class="qr-page">
      <img 
        class="qr-page__smile-img"
        src="@/assets/food_smile.png" alt=""
      >
      <p 
        class="qr-page__title h5"
      >Покажите офицанту ваш QR и получите свою Еду</p>
      <p 
        v-if="qrGenerateErr"
      >Возникла ошибка при генерации QR-кода</p>
      <img 
        v-else-if="!qrGenLoading"
        :src="imgDataUrl" 
        alt="QR"
      >
    </div>
  </main>
</template>

<script>
import { toDataURL } from 'qrcode';
const qrOptions = { 
  type: 'image/jpeg',
  quality: 0.3,
  margin: 1,
  color: {
    dark:"#000000",
    // light:"#FFBF60FF"
  }
}
export default {
  data(){
    return {
      qrGenerateErr: false,
      qrGenLoading: true,
      imgDataUrl: undefined,
    }
  },
  methods: {
    async generateQrCode(str){
      this.qrGenLoading = true;
      try {
        this.imgDataUrl = await toDataURL(str, qrOptions)
      } catch {
        this.qrGenerateErr = true;
      }
      this.qrGenLoading = false;
    }
  },
  mounted(){
    this.generateQrCode('https://assets-prd.ignimgs.com/2020/10/01/borat2-button-1601576298983.jpg')
  }
}
</script>

<style lang="scss">
.qr-page{
  padding: 48px 32px;
  background: #FFFFFF;
  border-radius: 12px;
  text-align: center;
  .qr-page__smile-img{
    width: 64px;
    height: auto;
    margin-bottom: 20px;
  }
  .qr-page__title{
    margin-bottom: 45px;
  }
}
</style>