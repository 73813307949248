<template>
  <div class="header--qr">
    <router-link
      replace
      :to="{ name: 'FoodPage' }"
      class="header--qr__back-link"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 4L8 12L16 20" stroke="#151C22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </router-link>
  </div>
</template>

<script>
// import Spinner from "@/components/partials/Spinner.vue"
export default {
  name: "FoodQrHeaderView",
  mounted(){
    this.$store.dispatch('layout/setThemeColor', '#F6F6F6');
    this.$nextTick(() => {
      let headerHeight = this.$el.parentElement.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  },

  updated(){
    this.$store.dispatch('layout/setThemeColor', '#F6F6F6');
    this.$nextTick(() => {
      let headerHeight = this.$el.parentElement.offsetHeight
      this.$store.dispatch('layout/setHeaderHeight', headerHeight)
    })
  }
}
</script>
<style lang="scss">
.header--qr{
  display: flex;
  // justify-content: space-between;
  align-items: center;
  justify-content: space-between;
}
.header--qr__back-link{
  display: flex; 
  align-items: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  margin: 0 12px 0 0;
  // overflow-wrap: anywhere;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg{
    flex-shrink: 0;
  }
}
</style>